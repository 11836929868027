import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import '@/assets/js/global'
import '@/assets/js/directives'
// import '@/assets/css/popupStyle.scss'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/display.css';
import 'element-ui/lib/theme-chalk/index.css'
import axios from 'axios'
import dayjs from 'dayjs'
import currency from 'currency.js';
Vue.use(ElementUI)
Vue.prototype.$axios = axios
Vue.prototype.$dayjs = dayjs
Vue.prototype.$currency = currency


import showPic from '@/components/showPic/index.js'
Vue.use(showPic)

import ImageModal from '@/components/common/imageModal/index.js'
Vue.use(ImageModal)

import Authorization from '@/components/common/Authorization'
Vue.component('Authorization',Authorization)

import '@/assets/css/index.scss'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
