const cycles = {
  yn: [
    {
      value: 1,
      text: "是",
    },
    {
      value: 0,
      text: "否",
    },
  ],
  fundType: [
    {
      value: 0,
      text: "现金",
    },
    {
      value: 1,
      text: "银行",
    },
    {
      value: 2,
      text: "其它",
    },
  ],
  cashierFundType: [
    {
      value: 0,
      text: "现金",
    },
    {
      value: 1,
      text: "银行",
    },
    {
      value: 2,
      text: "其它",
    },
    {
      value: 3,
      text: "往来",
    },
  ],
  balanceDirection: [
    {
      value: 0,
      text: "借",
    },
    {
      value: 1,
      text: "贷",
    },
    {
      value: 2,
      text: "平",
    },
  ],
  classDirection: [
    {
      value: 0,
      text: "借",
    },
    {
      value: 1,
      text: "贷",
    },
    {
      value: 2,
      text: "平",
    },
  ],
  currency: [
    {
      value: "RMB",
      text: "人民币RMB",
    },
    {
      value: "HKD",
      text: "港元HKD",
    },
    {
      value: "USD",
      text: "美元USD",
    },
    {
      value: "EUR",
      text: "欧元EUR",
    },
    {
      value: "GBP",
      text: "英镑GBP",
    },
    {
      value: "JPY",
      text: "日元JPY",
    },
    {
      value: "OTHER",
      text: "其他OTHER",
    },
  ],
  bank: [
    {
      value: 0,
      text: "工商银行",
    },
    {
      value: 1,
      text: "农业银行",
    },
    {
      value: 2,
      text: "建设银行",
    },
    {
      value: 3,
      text: "其他银行",
    },
  ],
  targetType: [
    {
      value: 0,
      text: "人员",
    },
    {
      value: 1,
      text: "客户",
    },
    {
      value: 2,
      text: "供应商",
    },
    {
      value: 3,
      text: "其他",
    },
  ],
  objectMent: [
    {
      value: 0,
      text: "内部个人",
    },
    {
      value: 1,
      text: "外部个人",
    },
    {
      value: 2,
      text: "内部单位",
    },
    {
      value: 3,
      text: "外部单位",
    },
  ],
  rosterType: [
    {
      value: 0,
      text: "名单录入",
    },
    {
      value: 1,
      text: "登记人员",
    },
    {
      value: 2,
      text: "临时人员",
    },
  ],
  registerType: [
    {
      value: 0,
      text: "支付",
    },
    {
      value: 1,
      text: "收入",
    },
    {
      value: 2,
      text: "往来",
    },
  ],
  costPeriodType: [
    {
      value: 0,
      text: "单笔提交",
    },
    {
      value: 1,
      text: "多周期拆分提交",
    },
    {
      value: 2,
      text: "长周期固定提交",
    },
  ],
  process: [
    {
      value: 0,
      text: "待审批",
    },
    {
      value: 1,
      text: "待处理",
    },
    {
      value: 2,
      text: "已被拒",
    },
    {
      value: 3,
      text: "待对账",
    },
    {
      value: 4,
      text: "已对账",
    },
  ],
  classTag: [
    {
      value: 0,
      text: "租行订单费用",
    },
    {
      value: 1,
      text: "租行计收账单",
    },
    {
      value: 2,
      text: "租行计付账单",
    },
    {
      value: 3,
      text: "生活服务计收",
    },
    {
      value: 4,
      text: "生活服务计付",
    },
    {
      value: 5,
      text: "车辆维保费用",
    },
    {
      value: 6,
      text: "人员薪酬福利",
    },
    {
      value: 7,
      text: "会务活动进销",
    },
  ],
  examine: [
    {
      value: 1,
      text: "需审批",
    },
    {
      value: 0,
      text: "无需审批",
    },
  ],
  role: [
    {
      value: 'admin',
      text: "超管",
    },
    {
      value: 'finance',
      text: "财务",
    },
    {
      value: 'accounting',
      text: "会计",
    },
    {
      value: 'common',
      text: "查看",
    },
  ],
};

function getText() {
  let exVals = {};
  for (let i in cycles) {
    let arr = [];
    cycles[i].map((item) => {
      arr.push(item.text);
    });
    exVals[i] = arr;
  }
  return exVals;
}

// 知道具体数组名，根据val获取text
function getMapText(field, value) {
  let mapVal = "";
  if (field && cycles[field]) {
    for (let i = 0; i < cycles[field].length; i++) {
      if (cycles[field][i].value === value) {
        mapVal = cycles[field][i].text;
        break;
      }
    }
  }
  return mapVal;
}

function getMap(field) {
  var stateMap = {};
  for (let index = 0; index < cycles[field].length; index++) {
    const element = cycles[field][index];
    stateMap[element.value] = element.text;
  }
  return stateMap;
}

export { cycles, getText, getMapText, getMap };
