import store from "../store/index.js";
import Vue from "vue";
import VueRouter from "vue-router";
import { findByLoginInfo } from '@/api/finance.js'

// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const NotFound = () => import("@/components/404.vue");
const Home = () => import("@/components/layout/home");
const Manage = () => import("@/components/layout/manage");

const CashierAccount = () => import("@/views/finance/cashierAccount/index");
const pay = () => import("@/views/finance/record/pay");
const income = () => import("@/views/finance/record/income");
const dealings = () => import("@/views/finance/record/dealings");
const account = () => import("@/views/finance/association/account");
const currentAccounts = () =>
  import("@/views/finance/association/currentAccounts");
const payObject = () => import("@/views/finance/association/payObject");
const payClass = () => import("@/views/finance/association/payClass");
const cycleFee = () => import("@/views/finance/feeAssociation/cycleFee");
const asset = () => import("@/views/finance/feeAssociation/asset");
const invoice = () => import("@/views/finance/feeAssociation/invoice");

const routes = [
  {
    path: "/",
    component: Home,
    // redirect: "/manage",
  },
  {
    path: "/manage",
    name: "finance",
    root: "finance",
    redirect: "/cashierAccount",
    component: Home,
    menuShow: true,
    meta: {
      title: "收支管理",
    },
    children: [
      {
        path: "refresh",
        name: "刷新",
        component: Manage,
        iconCls: "iconfont icon-shuaxintubiao",
        menuShow: true,
        isBtn: true, // 新增订单按钮
        leaf: true, // 只有一个节点
        meta: {
          title: "收支管理",
        },
      },
      {
        path: "/cashierAccount",
        name: "出纳台账",
        redirect: "/cashierAccount/list",
        component: Manage,
        iconCls: "iconfont icon-yunliliebiao",
        menuShow: true,
        leaf: true, // 只有一个节点
        children: [
          {
            path: "/cashierAccount/list",
            component: CashierAccount,
            name: "出纳台账",
            menuShow: true,
            meta: {
              title: "收支管理",
              name: 'cashierAccount'
            },
          },
        ],
      },
      {
        path: "/record",
        name: "财务收支",
        component: Manage,
        iconCls: "iconfont icon-baojiadan",
        redirect: "/record/pay",
        menuShow: true,
        children: [
          {
            path: "/record/pay",
            component: pay,
            name: "支付登记",
            menuShow: true,
            isDisabled: false,
            meta: {
              title: "收支管理",
              name: 'pay'
            },
          },
          {
            path: "/record/income",
            component: income,
            name: "收入登记",
            menuShow: true,
            isDisabled: false,
            meta: {
              title: "收支管理",
              name: 'income'
            },
          },
          {
            path: "/record/dealings",
            component: dealings,
            name: "往来登记",
            menuShow: true,
            isDisabled: false,
            meta: {
              title: "收支管理",
              name: 'dealings'
            },
          },
        ],
      },
      {
        path: "/association",
        name: "收支关联",
        component: Manage,
        iconCls: "iconfont icon-yewupeizhi",
        redirect: "/association/account",
        menuShow: true,
        children: [
          {
            path: "/association/account",
            component: account,
            name: "资金账号",
            menuShow: true,
            isDisabled: false,
            meta: {
              title: "收支管理",
              name: 'account'
            },
          },
          {
            path: "/association/currentAccounts",
            component: currentAccounts,
            name: "往来账款",
            menuShow: true,
            isDisabled: false,
            meta: {
              title: "收支管理",
              name: 'currentAccounts'
            },
          },
          {
            path: "/association/payObject",
            component: payObject,
            name: "收支对象",
            menuShow: true,
            isDisabled: false,
            meta: {
              title: "收支管理",
              name: 'payObject'
            },
          },
          {
            path: "/association/payClass",
            component: payClass,
            name: "收支类别",
            menuShow: true,
            isDisabled: false,
            meta: {
              title: "收支管理",
              name: 'payClass'
            },
          },
        ],
      },
      {
        path: "/feeAssociation",
        name: "费用关联",
        component: Manage,
        iconCls: "iconfont icon-jiesuanzhongxin",
        redirect: "/feeAssociation/cycleFee",
        menuShow: true,
        children: [
          {
            path: "/feeAssociation/cycleFee",
            component: cycleFee,
            name: "周期费用",
            menuShow: true,
            isDisabled: false,
            meta: {
              title: "收支管理",
              name: 'cycleFee'
            },
          },
          {
            path: "/feeAssociation/asset",
            component: asset,
            name: "资产列表",
            menuShow: false,
            isDisabled: false,
            meta: {
              title: "收支管理",
              name: 'asset'
            },
          },
          {
            path: "/feeAssociation/invoice",
            component: invoice,
            name: "费用发票",
            menuShow: true,
            isDisabled: false,
            meta: {
              title: "收支管理",
              name: 'invoice'
            },
          },
        ],
      },
    ],
  },
  {
    path: "*",
    component: NotFound,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// 路由跳转时将路由添加到路由标签列表
function addStepRouter(item) {
  let isFind = false;
  store.state.stepRouterList.find(function (value, index) {
    if (value.name === item.name) {
      store.commit("setStepRouterIndex", index);
      isFind = true;
    }
  });
  if (!isFind) {
    store.commit("pushStepRouterList", item);
    let index = store.state.stepRouterList.length - 1;
    store.commit("setStepRouterIndex", index);
  }
}

router.beforeEach(async (to, from, next) => {

  if(sessionStorage.getItem('x-access-token')) {
    await findByLoginInfo().then(res=> {
      store.commit("setRole", res.item);
    })
  }

  let user = null;
  if (user) {
    if (to.name === "cpManageOrder") {
      // 如果是跳转回主页
      if (from.meta && from.meta.root === "companyManage") {
        // 企业管理
        next({
          path: "/cpManage/company",
        });
      } else if (from.meta && from.meta.root === "financeManage") {
        // 财务管理
        next({
          path: "/cpManage/pay",
        });
      } else if (from.meta && from.meta.root === "personalManage") {
        // 个人管理
        next({
          path: "/cpManage/person",
        });
      } else {
        next();
      }
    } else {
      //存在子路由时才添加到路由标签列表
      if (JSON.stringify(to.meta) !== "{}" && to.meta.title !== "企业V袋") {
        addStepRouter(to);
      }
      next();
    }
  } else {
    // sessionStorage.removeItem("tempLogin");
    // sessionStorage.removeItem("businessLogin");
    // next({
    //   path: "/login",
    // });
    if (JSON.stringify(to.meta) !== "{}") {
      addStepRouter(to);
    }
    next(); // 开发
  }
});

export default router;
