import Vue from 'vue'
import { cycles, getText, getMapText, getMap } from './selectData'
import regex from './regex'
import dayjs from 'dayjs'
import store from "@/store/index.js";
import { MessageBox, Notification } from 'element-ui'

Vue.prototype.cycles = cycles
Vue.prototype.getText = getText()
Vue.prototype.getMapText = getMapText
Vue.prototype.getMap = getMap
Vue.prototype.regex = regex

//获取sessionStorage
Vue.prototype.getsStorage = function (name) {
  let tmp = sessionStorage.getItem(name)
  return JSON.parse(tmp)
}
Vue.prototype.getlStorage = function (name) {
  let tmp = localStorage.getItem(name)
  return JSON.parse(tmp)
}

Vue.prototype.getEnumText = function (field, name) {
  let mapVal = "";
  let enums = JSON.parse(sessionStorage.getItem('enums'))
  if (field && enums[field]) {
    for (let i = 0; i < enums[field].length; i++) {
      if (enums[field][i].name === name) {
        mapVal = enums[field][i].label;
        break;
      }
    }
  }
  return mapVal;
}

Vue.prototype.$authEnum = {
  admin: '超管',
  finance: '财务',
  accounting: '会计',
  common: '查看',
}
Vue.prototype.$authentication = function () {
  let res = false

  let user = this.getsStorage("access-user");
  if (user) {
    sessionStorage.setItem('access-user', JSON.stringify(user))

    let authTime = user.authTime || 0;
    let isExpire = true;
    isExpire = this.$dayjs().isAfter(this.$dayjs(authTime));

    if (!isExpire) {
      res = true
    }
  }

  return res
}

Vue.prototype.$verifyRole = function (role, isOpen) {
  return new Promise((resolve, reject) => {
    let res = false
    let roles = role || []

    if (store.state.isAdmin) {
      res = true
    } else if (roles.includes('超管')) {
      res = Boolean(store.state.isAdmin)
    } else {
      roles.forEach(item => {
        if (store.state.role.includes(item)) {
          res = true
        }
      })
    }

    let user = sessionStorage.getItem('access-user') ? JSON.parse(sessionStorage.getItem('access-user')) : ''

    if (user && user.authTime) {
      if (this.$dayjs(user.authTime).isAfter(this.$dayjs())) {
        res = true
      }
    }

    if (res) {
      resolve()
    } else {
      if (isOpen) {
        store.commit('setAuthVisible', true)
      } else {
        MessageBox.alert('您不具备该操作权限，请与超管人员联系', '提示', { type: 'warning' }).then(res => {
        }).catch(() => { })
      }
      // reject()
    }
  })
}

Vue.prototype.$roleResult = function (role) {
  let res = false
  let roles = role || []

  if (store.state.isAdmin) {
    res = true
  } else if (roles.includes('超管')) {
    res = Boolean(store.state.isAdmin)
  } else {
    roles.forEach(item => {
      if (store.state.role.includes(item)) {
        res = true
      }
    })
  }

  let user = sessionStorage.getItem('access-user') ? JSON.parse(sessionStorage.getItem('access-user')) : ''

  if (user && user.authTime) {
    if (this.$dayjs(user.authTime).isAfter(this.$dayjs())) {
      res = true
    }
  }

  return res
}


Vue.prototype.pickerOptions = {
  shortcuts: [
    {
      text: "今天",
      onClick(picker) {
        const end = new Date();
        const start = new Date();
        picker.$emit("pick", [start, end]);
      },
    },
    {
      text: "近七天",
      onClick(picker) {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
        picker.$emit("pick", [start, end]);
      },
    },
    {
      text: "本月",
      onClick(picker) {
        let now = dayjs()
        let s = now.format('YYYY-MM-01')
        let e = now.format('YYYY-MM-'+now.daysInMonth())

        const end = new Date(e);
        const start = new Date(s);
        picker.$emit("pick", [start, end]);
      },
    },
    {
      text: "近三个月",
      onClick(picker) {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
        picker.$emit("pick", [start, end]);
      },
    },
    {
      text: "近一年",
      onClick(picker) {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
        picker.$emit("pick", [start, end]);
      },
    },
  ],
}

// 日期时间转换（过滤器filter）
Vue.filter('formatDateTime', function (value, len, type) {
  if (!type) type = '/'
  if (!value) return ''
  let fmt = 'YYYY' + type + 'MM' + type + 'DD HH:mm'
  if (len === 3) { // 年月日
    fmt = 'YYYY' + type + 'MM' + type + 'DD'
  } else if (len === 4) { // 不显示年
    fmt = 'MM' + type + 'DD HH:mm'
  } else if (len === 6) { // 年月日时分秒
    fmt = 'YYYY' + type + 'MM' + type + 'DD HH:mm:ss'
  }
  return dayjs(value).format(fmt)
});


// import Env from '../../api/env';
// import {cycles, getText, getMapText} from './selectData'
// import { centrol } from './ruleSelectData'

// import regex from './regex'

// Vue.prototype.h5Domain = Env.h5Domain // h5项目域名

// //判断是否正式环境
// Vue.prototype.isProd = function getIsProd() {
//     let value = window.location.href;
//     if(value!==null || value !==''){
//         //使用split 进行分割，一定要进行字符串判空
//         let str = value.split("https://");
//         if(str.length===1) {
//             str = value.split("http://");
//         }
//         const domain = str[1].split("/")[0];
//         if(domain === 'www.vv-che.com' || domain === 'operate.vv-che.com') {
//             return true
//         } else {
//             return false;
//         }
//     }
//     return false;
// };
// // let aliOss = require('ali-oss')
// const oss = new OSS({
//     accessKeyId: 'LTAIcZppIodBTNw6',
//     accessKeySecret: 'B8d5IyFuI0Qvh6ydGNZ1B425B9CyZA',
//     region: 'oss-cn-shenzhen',
//     bucket: 'hires',
//     secure: true,
// })


// //图片上传name
// Vue.prototype.picName = function (name, userId) {
//     let date = new Date().getTime()
//     return name + userId + date + parseInt(Math.random()*100000)
// }
// //每页的条数
// Vue.prototype.defaultSize = 10
// //调整分页
// Vue.prototype.pageSizes = [10,30,50]
// //ali oss上传图片
// Vue.prototype.oss = oss
// // 获取车型大小
// Vue.prototype.getSize = function (seat) {
//     if (seat <= 4) {
//         return '小轿车'
//     } else if (seat <= 7 && seat >= 5) {
//         return '商务车'
//     } else if (seat <= 14 && seat >= 8) {
//         return '商旅车'
//     } else if (seat <= 23 && seat >= 15) {
//         return '中巴车'
//     } else if (seat <= 42 && seat >= 24) {
//         return '小型大巴'
//     } else if (seat <= 55 && seat >= 43) {
//         return '大型大巴'
//     } else if(seat > 55){
//         return '其他车型'
//     }
// }
// // 实施类别  0-责任分配  1-责任互换  2-责任解除  3-车辆抽查  4-车辆交付  5-车辆回收
// Vue.prototype.getEffectiveType = function(val) {
//     if(val===0) {
//         return '责任分配'
//     } else if(val===1) {
//         return '责任互换'
//     } else if(val===2) {
//         return '责任解除'
//     } else if(val===3) {
//         return '车辆抽查'
//     } else if(val===4) {
//         return '车辆交付'
//     } else if(val===5) {
//         return '车辆回收'
//     }
//     return ''
// }
// //计算驾龄 （月为单位）
// Vue.prototype.getDAge = function (date) {
//     if (!date) return '0年'
//     let now = new Date()

//     let old = new Date(date)
//     if (now <= old) {
//         return '0年'
//     }

//     let nowY = now.getFullYear()
//     let nowM = now.getMonth() + 1
//     let nowD = now.getDate()

//     let oldY = old.getFullYear()
//     let oldM = old.getMonth() + 1
//     let oldD = old.getDate()

//     let deltaD = nowD - oldD
//     // deltaD >= 0  大于算一月，不大于减一月
//     let m = (nowY - oldY) * 12

//     if (nowM >= oldM) {
//         m = m + nowM - oldM
//     } else {
//         m = m - (oldM - nowM)
//     }
//     let yue = deltaD >= 0 ? m : m - 1
//     if (yue <= 12) {
//         return yue + '个月'
//     } else {
//         return parseInt(yue / 12) + '年' + yue % 12 + '个月'
//     }

// }
// //驾照有效期限
// Vue.prototype.licenseDate = function (start, end) {
//     if (!start || !end) return ''
//     let startY = (new Date(start)).getFullYear()
//     let endY = (new Date(end)).getFullYear()
//     if (endY - startY <= 0) {
//         return ''
//     }
//     return endY - startY
// }

// Vue.prototype.cycles = cycles
// Vue.prototype.centrol = centrol
// Vue.prototype.regex = regex
// //车身颜色
// Vue.prototype.colors = cycles.colors
// Vue.prototype.getText = getText()
// Vue.prototype.getMapText = getMapText
// //输出 2019-12-12日这种格式
// Vue.prototype.useDate = function (value, type, style) {
//     if (!value) return ''
//     let date = new Date(value);
//     let y = date.getFullYear() + (style===1?'/':'-')
//     let mon = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + (style===1?'/':'-')
//     let d = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
//     let h = date.getHours()
//     let m = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
//     let sec = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();

//     if (type === 3) {
//         return y + mon + d + ' ' + h + ':' + m + ':' + sec
//     } else if(type===2){
//         return y + mon + d + ' ' + h + ':' + m
//     }else {
//         return y + mon + d
//     }

// }
// //返回月日 + 详细时间数组
// const timeDealFun = (value, type) => {
//     if (!value) return ''
//     let date = new Date(value);
//     let y = date.getFullYear()
//     let mon = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1)
//     let d = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
//     let h = date.getHours()< 10 ? '0' + date.getHours() : date.getHours()
//     let min = date.getMinutes()< 10 ? '0' + date.getMinutes() : date.getMinutes()
//     if (type === 1) {
//         return mon + '/' + d
//     } else if (type === 2) {
//         return h + ':' + min
//     }
// }
// Vue.prototype.timeDeal = timeDealFun
// Vue.filter('timeDeal', timeDealFun )
// Vue.prototype.getCompanyDays = function (date1, date2) {
//     let stamp1 = (new Date(date1)).getTime()
//     let stamp2 = (new Date(date2)).getTime()
//     if (stamp2 < stamp1) return
//     let result = Math.floor(((stamp2 - stamp1) * 0.001) / (3600 * 24))
//     return result + 1
// }
// Vue.prototype.computeNumbers = function(numbers) {
//     if (numbers) {
//         // 如果是手机号
//         if (numbers.length === 11) {
//             return numbers.substring(0, 3) + "****" + numbers.substring(7);
//         } else if (numbers.length >= 8) {
//             return numbers.substring(0, 4) + "****" + numbers.substring(numbers.length - 4);
//         } else if (numbers.length >= 3 && numbers.length < 8) {
//             return numbers.substring(0, 3) + "****";
//         }  else {
//             return "****";
//         }
//     }
//     return "-";
// };
// //按钮筛选功能
// Vue.prototype.filtrate = function (num, arrState, rules) {
//     //按钮可能的组合方法
//     let state = rules
//     let checked = []
//     // 记录当前选中了哪些
//     for (let i = 0; i < arrState.length; i++) {
//         if (arrState[i]) {
//             checked.push(i)
//         }
//     }
//     //如果没有任何的选中  直接点亮按钮
//     if (checked.length < 1) {
//         arrState.splice(num, 1, true)
//     } else {
//         //此时有按钮被选中，需判断 已有的和正在点击的分属于哪些组合
//         let current = []
//         let checkedLens = checked.length
//         let count = []  //统计checked 中数字出现在state中的次数，来判定到底属于哪个组合
//         for (let i = 0; i < state.length; i++) {
//             count.push(0)
//         }
//         for (let k = 0; k < state.length; k++) {
//             if (state[k].indexOf(num) >= 0) {
//                 current.push(k)
//             }
//             for (let i = 0; i < checked.length; i++) {
//                 if (state[k].indexOf(checked[i]) >= 0) {
//                     count[k]++
//                 }
//             }
//         }
//         //获取到已有的，有可能发生的组合
//         let possible = []  //可以进入的组合
//         for (let k = 0; k < count.length; k++) {
//             if (count[k] === checkedLens) {
//                 possible.push(k)
//             }
//         }

//         //current[]  和 possible[]  分别对应当前按钮可属组合，已存在按钮的可属组合
//         //当二者有交集时，可以继续操作，当二者没交集时，checked清空，以current[] 为主
//         let common = 0
//         for (let k = 0; k < current.length; k++) {
//             if (possible.indexOf(current[k]) >= 0) {
//                 //只要有一个交集，就能点击
//                 common++
//             }
//         }
//         if (common === 0) {
//             //没有交集  点亮自己灭掉其它
//             for (let i = 0; i < arrState.length; i++) {
//                 if (i === num) {
//                     arrState.splice(i, 1, true)
//                 } else {
//                     arrState.splice(i, 1, false)
//                 }
//             }
//         } else {
//             arrState.splice(num, 1, true)
//         }
//     }
//     let result = []
//     let index = -1
//     for (let i = 0; i < arrState.length; i++) {
//         if (arrState[i]) {
//             result.push(i)
//         }
//     }
//     let resStr = result.sort().toString()
//     for (let i = 0; i < state.length; i++) {
//         if (state[i].sort().toString() === resStr) {
//             index = i
//         }
//     }
//     return {arrState: arrState, index: index}
// }

// //图片压缩
Vue.prototype.compressPic = function (file, isWaterMake) {
  return new Promise((resolve) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function (e) {
      let canvas = document.createElement('canvas');
      let ctx = canvas.getContext('2d');
      let img = new Image;
      img.onload = function () {
        let width = img.width
        let height = img.height
        let flag = width > height ? width > 4096 ? true : false : height > 2160 ? true : false
        let rate = 1
        if (flag) {
          rate = width > height ? 4096 / width : 2160 / height
        } else {
          //如果没有大于1024px的边，那就按照体积的比例去压缩 1M/sizes

          rate = file.size > (1024 * 1024) ? (1024 * 1024) / file.size : 1
        }
        canvas.width = width * rate
        canvas.height = height * rate
        ctx.drawImage(img, 0, 0, width, height, 0, 0, width * rate, height * rate)
        if (isWaterMake) {

          let foCvs = document.createElement('canvas')
          let foCtx = foCvs.getContext('2d')
          foCvs.width = 180
          foCvs.height = 100
          // //水印旋转坐标
          foCtx.translate(80, 30)
          foCtx.rotate(-15 * Math.PI / 180);
          foCtx.translate(-80, -30)
          // //水印样式
          foCtx.fillStyle = 'rgba(33,43,102,0.3)'
          foCtx.font = "18px 宋体";
          foCtx.fillText('【VV租行】', 25, 40)
          foCtx.fillText('仅限信息证明使用', 10, 65)
          foCtx.fill()
          let pat = ctx.createPattern(foCvs, 'repeat');
          ctx.fillStyle = pat
          ctx.fillRect(0, 0, width * rate, height * rate)
        }
        let dataURL = canvas.toDataURL(file.type)
        let arr = dataURL.split(',')
        let mime = arr[0].match(/:(.*?);/)[1]
        //btoa 二进制-> ascii  二进制转base64
        //atob ascii-> 二进制  base64转二进制
        let bstr = atob(arr[1])
        let p = bstr.length
        let u8arr = new Uint8Array(p)
        while (p--) {
          // charCodeAt Unicode
          u8arr[p] = bstr.charCodeAt(p)
        }

        let newData = new File([u8arr], file.name, { type: mime })
        canvas = null

        resolve({ base64: dataURL, file: newData })
      }
      img.src = e.target.result
    }
  })
}

// //转base64
// Vue.prototype.getBase64 = function(file) {
//     return new Promise((resolve, reject) => {
//         let reader = new FileReader();
//         let fileResult = "";
//         reader.readAsDataURL(file);
//         //开始转
//         reader.onload = function() {
//             fileResult = reader.result;
//         };
//         //转失败
//         reader.onerror = function(error) {
//             reject(error);
//         };
//         //转结束就resolve 出去
//         reader.onloadend = function() {
//             resolve(fileResult);
//         };
//     });
// };
