import Vue from 'vue'
import Modal from './imageModal'

const ImageModal = Vue.extend(Modal)
function createImgModal (title, data, modal=false) {
  const instance = new ImageModal({
    data () {
      return {
        data,
        title,
        modal,
        visible: false
      }
    },
    methods: {
      close () {
        this.visible = false
      },
      show (title, data) {
        if (Array.isArray(data) && data.length) {
          this.data = data
        }
        this.title = title
        this.visible = true
      },
      destory () {
        this.$destroy()
        document.body.removeChild(this.$el)
      }
    }
  }).$mount()
  this.$on('hook:beforeDestroy', () => {
    instance.destory()
  })

  this.$forceUpdate()
  document.body.appendChild(instance.$el)

  return instance
}

export default {
  install: (Vue, config={}) => {
    Vue.prototype.$createImgModal = createImgModal
  }
}
