<template>
  <el-dialog
    custom-class="dialogVisible"
    v-if="imgFlag"
    :visible.sync="imgFlag"
    :title="imgName"
    width="600px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="close"
    :modal="false"
    v-dialogDragOnly
    append-to-body
  >
    <div id="show">
      <template v-if="imgType === 1">
        <video :src="urls[0]" controls="controls" width="100%" height="500">
          您的浏览器不支持 video 标签。
        </video>
      </template>
      <template v-else>
        <div class="inner">
          <div
            class="arrow-right arrow"
            @click="move(1)"
            v-if="urls.length > 1"
          >
            <i class="el-icon-arrow-right"></i>
          </div>
          <div
            class="arrow-left arrow"
            @click="move(-1)"
            v-if="urls.length > 1"
          >
            <i class="el-icon-arrow-left"></i>
          </div>
          <div class="img-uls" :style="{ width: urls.length * 600 + 'px' }">
            <div class="img-box" v-for="(item, index) in urls" :key="index">
              <!-- <img :src="item" alt="" /> -->
              <el-image
                style="max-height: 100%; max-width: 100%"
                :src="item"
                :preview-src-list="urls"
              >
              </el-image>
            </div>
          </div>
        </div>
      </template>
    </div>
  </el-dialog>
</template>
<script type="text/ecmascript-6">
export default {};
</script>
<style lang="scss" scoped>
#show {
  padding: 10px 0 20px 0px;
  .inner {
    height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
    .img-uls {
      height: 100%;
      transition: all 300ms;
    }
    .img-box {
      width: 600px;
      height: 100%;
      float: left;
      text-align: center;
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
    .arrow {
      width: 30px;
      height: 30px;
      background: rgba(0, 0, 0, 0.3);
      border-radius: 50%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      z-index: 50;
      i {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 16px;
        color: #fff;
      }
      &:hover {
        opacity: 0.8;
      }
    }

    .arrow-right {
      right: 20px;
    }
    .arrow-left {
      left: 20px;
    }
  }
}
</style>
