import * as API from './index'
import request from './request'

// 登录
export const login = (data) => API.POST('member/login', data);
// 验证登录
export const validToken = (data) => API.GET('user/validToken', data);
// 缓存枚举常量
export const findEnumConstant = (data) => API.GET('enumConstant/findEnumConstant', data);
// 获取当前登录用户权限信息
export const findByLoginInfo = (data) => API.GET('member/findByLoginInfo', data);
// 分页查询银行信息
export const pageBankType = (data) => API.GET('bankType/pageBankType', data);
// 根据业务id修改凭证图片
export const updateBusinessIdAndType = (data) => API.POST('finance/updateBusinessIdAndType', data);

// 分页查询出纳台账
export const pageCashierAccount = (data, signal) => API.POST('cashierAccount/pageCashierAccount', data, signal);
// 查询出纳台账小计
export const pageCashierAccountSumAmount = (data, signal) => API.POST('cashierAccount/pageCashierAccountSumAmount', data, signal);
// 新增保存费用登记
export const listSaveFeeRegistration = (data) => API.POST('feeRegistration/listSaveFeeRegistration', data);
// 根据出纳台账id获取详情资料
export function findByCashierAccountId(data) {
  return request({
    url: 'cashierAccount/findByCashierAccountId',
    method: 'post',
    params: data
  })
}
// 资金互转
export const saveMutualTransferFunds = (data) => API.POST('cashierAccount/saveMutualTransferFunds', data);
// 删除出纳台账
export const removedCashierAccountCode = (data) => API.GET('cashierAccount/removedCashierAccountCode', data);
// 导出流水
export const exportCashierAccount = (data) => API.GET('cashierAccount/exportCashierAccount', data);

// 保存模板资金互转
export const saveTemplateBank = (data) => API.POST('templateBank/saveTemplateBank', data);
// 获取模板列表
export const pageTemplateBank = (data) => API.GET('templateBank/pageTemplateBank', data);
// 删除模板
export const deleteTemplateBank = (data) => API.GET('templateBank/deleteTemplateBank', data);
// 获取模板详情
export const detailTemplateBank = (data) => API.GET('templateBank/detailTemplateBank', data);
// 获取模板名称
export const findByTemplateBankName = (data) => API.GET('templateBank/findByTemplateBankName', data);


// 分页查询准登名单
export const pageRoster = (data) => API.GET('roster/pageRoster', data);
// 保存准登名单
export const saveRoster = (data) => API.POST('roster/saveRoster', data);
// 准登名单详情
export const detailRoster = (data) => API.GET('roster/detailRoster', data);
// 批量编辑保存
export const updateAllRoster = (data) => API.POST('roster/updateAllRoster', data);
// 批量删除准登名单
export const detailAllRoster = (data) => API.POST('roster/detailAllRoster', data);
// 批量启停
export const updateAllRosterStatus = (data) => API.POST('roster/updateAllRosterStatus', data);
// 批量导入临时、登记人员
export const saveImportRoster = (data) => API.POST('roster/saveImportRoster', data);


// 分页查询支付登记
export const pageFinance = (data) => API.POST('finance/pageFinance', data);
// 支付登记详情
export function detailFinance(data) {
  return request({
    url: 'finance/detailFinance',
    method: 'post',
    params: data
  })
}
// 分页查询小计金额
export const pageFinanceSumAmount = (data) => API.POST('finance/pageFinanceSumAmount', data);
// 批量修改同意
export const updateBatchModify = (data) => API.POST('finance/updateBatchModify', data);
// 批量修改拒绝
export const updateBatchModifyRefuse = (data) => API.POST('finance/updateBatchModifyRefuse', data);
// 批量删除
export const updateBatchModifyRemoved = (data) => API.POST('finance/updateBatchModifyRemoved', data);
// 批量移除
export const updateBatchModifyDelete = (data) => API.POST('finance/updateBatchModifyDelete', data);
// 费用处理
export const saveAllPayFinance = (data) => API.POST('finance/saveAllPayFinance', data);
// 多周期修改保存
export const updateBusiness = (data) => API.GET('cycle/updateBusiness', data);
// 提交人
export function findByPresenter(data) {
  return request({
    url: 'finance/findByPresenter',
    method: 'post',
    params: data
  })
}
// 费用关联
export function findByAssetRecordName(data) {
  return request({
    url: 'finance/findByAssetRecordName',
    method: 'post',
    params: data
  })
}

// 分页查询收入登记
export const pageIncome = (data) => API.POST('income/pageIncome', data);
// 分页查询小计金额
export const findByIncomeSumAmount = (data) => API.POST('income/findByIncomeSumAmount', data);
// 批量修改同意
export const incomeUpdateBatchModify = (data) => API.POST('income/updateBatchModify', data);
// 批量修改拒绝
export const incomeUpdateBatchModifyRefuse = (data) => API.POST('income/updateBatchModifyRefuse', data);
// 批量删除
export const incomeRemoved = (data) => API.POST('income/updateBatchModifyRemoved', data);
// 批量移除
export const incomeDelete = (data) => API.POST('income/updateBatchModifyDelete', data);
// 费用处理
export const saveAllPayIncome = (data) => API.POST('income/saveAllPayIncome', data);


// 分页查询往来登记
export const pageDealings = (data) => API.POST('dealings/pageDealings', data);
// 分页查询小计金额
export const findByDealingsSumAmount = (data) => API.POST('dealings/findByDealingsSumAmount', data);

// 批量修改同意
export const dealingsUpdateBatchModify = (data) => API.POST('dealings/updateBatchModify', data);
// 批量修改拒绝
export const dealingsUpdateBatchModifyRefuse = (data) => API.POST('dealings/updateBatchModifyRefuse', data);
// 批量删除
export const dealingsRemoved = (data) => API.POST('dealings/updateBatchModifyRemoved', data);
// 批量移除
export const dealingsDelete = (data) => API.POST('dealings/updateBatchModifyDelete', data);
// 费用处理
export const dealingsSaveAllPayIncome = (data) => API.POST('dealings/saveAllPayIncome', data);
// 获取审批人列表
export const getExamine = (data) => API.GET('approvalRecord/findByBusinessId', data);
// 审批
export const updateApprovalRecordId = (data) => API.GET('approvalRecord/updateApprovalRecordId', data);
// 获取当前企业资产名称
export const findByAssetRecord = (data) => API.GET('assetRecord/findByAssetRecordName', data);


// 保存资金账号
export const saveBank = (data) => API.POST('bank/saveBank', data);
// 删除
export const deleteBank = (data) => API.GET('bank/deleteBank', data);
// 资金账号详情
export const detailBank = (data) => API.GET('bank/detailBank', data);
// 分页查询资金账号
export const pageBank = (data) => API.POST('bank/pageBank', data);
// 查询相加总金额
export const findByBankSumAmount = (data) => API.POST('bank/findByBankSumAmount', data);
// 暂停或启用账号
export const updateBankStatus = (data) => API.GET('bank/updateBankStatus', data);
// 获取资金名称
export const findByBankName = (data) => API.GET('bank/findByBankName', data);
// 获取日期范围内对账数值
export const findByBankNameAndTime = (data) => API.GET('bank/findByBankNameAndTime', data);
// 提交对账
export const saveBankRecord = (data) => API.POST('bankRecord/saveBankRecord', data);
// 对账记录
export const pageBankRecord = (data) => API.GET('bankRecord/pageBankRecord', data);
// 查询资金账号是否存在
export const findByAccountNumber = (data) => API.GET('bank/findByAccountNumber', data);
// 恢复账号
export const updateBankRestore = (data) => API.GET('bank/updateBankRestore', data);
// 移除账号
export const removedBank = (data) => API.GET('bank/removedBank', data);
// 获取所有户名
export const findByAccountName = (data) => API.GET('bank/findByAccountName', data);
// 根据户名查询账号
export const findByAccountNameAndBank = (data) => API.GET('bank/findByAccountNameAndBank', data);
// 下载模板
export const downloadBankTemplate = (data) => API.GET('bank/downloadBankTemplate', data);
// 账号树形返回
export function accountCascade(data) {
  return request({
    url: 'bank/findByAccountNameCascade',
    method: 'post',
    params: data
  })
}
// 初始化期初对账记录
export function saveInitBankRecord(data) {
  return request({
    url: 'bankRecord/saveInitBankRecord',
    method: 'post',
    params: data
  })
}

// 分页查询往来账款
export const pageCurrentAccounts = (data) => API.POST('currentAccounts/pageCurrentAccounts', data);
// 往来账款详情
export const detailCurrentAccounts = (data) => API.GET('currentAccounts/detailCurrentAccounts', data);
// 保存期初账款
export const saveCurrentAccounts = (data) => API.POST('currentAccounts/saveCurrentAccounts', data);
// 查询相加总金额
export const findByCurrentAccountsSumAmount = (data) => API.POST('currentAccounts/findByCurrentAccountsSumAmount', data);
// 获取日期范围内对账数值
export const findChangeAndTime = (data) => API.GET('currentAccounts/findByBankNameAndTime', data);
// 删除往来账款
export const deleteCurrentAccounts = (data) => API.GET('currentAccounts/deleteCurrentAccounts', data);
// 获取对账记录
export const pageAccountsRecord = (data) => API.GET('accountsRecord/pageAccountsRecord', data);
// 保存对账记录
export const saveAccountsRecord = (data) => API.POST('accountsRecord/saveAccountsRecord', data);
// 删除对账记录
export const deleteAccountsRecord = (data) => API.GET('accountsRecord/deleteAccountsRecord', data);
// 删除资金对账记录
export const deleteBankRecord = (data) => API.GET('bankRecord/deleteBankRecord', data);
// 根据id修改标签
export const updateCurrentAccountsLabel = (data) => API.GET('currentAccounts/updateCurrentAccountsLabel', data);
// 编辑期初
export const saveOpening = (data) => API.POST('opening/saveOpening', data);
// 期初详情
export const findByCurrentAccountId = (data) => API.GET('opening/findByCurrentAccountId', data);
// 标签
export function findByCurrentAccountsLabel() {
  return request({
    url: 'currentAccounts/findByCurrentAccountsLabel',
    method: 'post',
  })
}

// 保存收支对象
export const saveExpensesTarget = (data) => API.POST('expensesTarget/saveExpensesTarget', data);
// 批量导入收支对象
export const batchSaveExpensesTarget = (data) => API.POST('expensesTarget/batchSaveExpensesTarget', data);
// 收支对象详情
export const detailExpensesTarget = (data) => API.GET('expensesTarget/detailExpensesTarget', data);
// 分页查询收支对象
export const pageExpensesTarget = (data) => API.GET('expensesTarget/pageExpensesTarget', data);
// 收支对象金额小计
export const findBySumExpensesTarget = (data) => API.GET('expensesTarget/findBySumExpensesTarget', data);
// 暂停或启用收支对象
export const updateIsSuspend = (data) => API.GET('expensesTarget/updateIsSuspend', data);
// 删除收支对象
export const deleteExpensesTarget = (data) => API.GET('expensesTarget/deleteExpensesTarget', data);
// 恢复收支对象
export const updateRestore = (data) => API.GET('expensesTarget/updateRestore', data);
// 获取不同分类对象集合
export const findTargetType = (data) => API.GET('expensesTarget/findTargetType', data);
// 获取对象性质
export const findByProperties = (data) => API.GET('expensesTarget/findByProperties', data);
// 获取指定收支列表
export const findByExpenditure = (data) => API.GET('expensesTarget/findByExpenditure', data);
// 左扩收支对象详情小计
export function findByIncomeRegistration(data) {
  return request({
    url: 'expensesTarget/findByIncomeRegistration',
    method: 'post',
    params: data
  })
}
// 左扩收支对象详情记录
export function findByExpensesTypeRecords(data) {
  return request({
    url: 'expensesTarget/findByExpensesTypeRecords',
    method: 'post',
    params: data
  })
}
// 费用对象弹窗保存绑定关系
export const saveTargetAndBanding = (data) => API.POST('expensesTarget/saveTargetAndBanding', data);
// 获取近似名称
export function findByName(data) {
  return request({
    url: 'expensesTarget/findByName',
    method: 'post',
    params: data
  })
}
// 树形接口返回查询
export function objectCascade(data) {
  return request({
    url: 'expensesTarget/findByExpensesCascade',
    method: 'post',
    params: data
  })
}


// 保存收支类别
export const saveExpensesType = (data) => API.POST('expensesType/saveExpensesType', data);
// 收支类别详情
export const detailExpensesType = (data) => API.GET('expensesType/detailExpensesType', data);
// 分页查询收支类别
export const pageExpensesType = (data) => API.GET('expensesType/pageExpensesType', data);
// 暂停收支类别
export const updateExpensesStatus = (data) => API.GET('expensesType/updateExpensesStatus', data);
// 删除收支类别
export const deleteExpensesType = (data) => API.GET('expensesType/deleteExpensesType', data);
// 恢复收支类别
export const updateTypeRestore = (data) => API.GET('expensesType/updateRestore', data);
// 恢复收支类别
export const findBySubjectType = (data) => API.GET('/subject/findBySubjectType', data);
// 获取财务科目类别
export const findByExpensesType = (data) => API.GET('expensesType/findByExpensesType', data);
// 类别左扩小计
export function findByExpensesRecords(data) {
  return request({
    url: 'expensesType/findByExpensesRecords',
    method: 'post',
    params: data
  })
}
// 类别左扩记录列表
export function findByExpensesRecordsDetail(data) {
  return request({
    url: 'expensesType/findByExpensesRecordsDetail',
    method: 'post',
    params: data
  })
}
// 类别标签
export function findByExpensesLabel() {
  return request({
    url: 'expensesType/findByExpensesLabel',
    method: 'post'
  })
}
// 一级类别
export function findByExpensesOne() {
  return request({
    url: 'expensesType/findByExpensesOne',
    method: 'post'
  })
}
// 二级类别
export function findByExpensesTwo() {
  return request({
    url: 'expensesType/findByExpensesTwo',
    method: 'post'
  })
}
// 树形接口返回查询
export function findByExpensesCascade(data) {
  return request({
    url: 'expensesType/findByExpensesCascade',
    method: 'post',
    params: data
  })
}
// 批量移除收支对象
export const removeObject = (data) => API.POST('expensesTarget/updateBatchModifyDelete', data);
// 批量移除收支类别
export const removeClass = (data) => API.POST('expensesType/updateBatchModifyDelete?token=' + sessionStorage.getItem('token'), data);


// 分页查询费用周期
export const pageExpenditure = (data) => API.POST('expenditure/pageExpenditure', data);
// 查询相加总金额
export const findByExpenditureSumAmount = (data) => API.POST('expenditure/findByExpenditureSumAmount', data);
// 费用周期详情
export const detailExpenditure = (data) => API.GET('expenditure/detailExpenditure', data);
// 删除费用周期
export const deleteExpenditure = (data) => API.GET('expenditure/deleteExpenditure', data);
// 暂停或启用
export function updateExpenditureStatus(data) {
  return request({
    url: 'expenditure/updateExpenditureStatus',
    method: 'post',
    params: data
  })
}
// 修改责任人
export function updateExpenditureUsername(data) {
  return request({
    url: 'expenditure/updateExpenditureUsername',
    method: 'post',
    params: data
  })
}
// 处理记录查询
export function findByProcessingRecords(data) {
  return request({
    url: 'expenditure/findByProcessingRecords',
    method: 'post',
    params: data
  })
}

// 保存模板
export const saveTemplate = (data) => API.POST('template/saveTemplate', data);
// 分页
export const pageTemplate = (data) => API.GET('template/pageTemplate', data);
// 详情
export const detailTemplate = (data) => API.GET('template/detailTemplate', data);
// 删除
export const deleteTemplate = (data) => API.GET('template/deleteTemplate', data);
// 上传文件
export const UploadPOST = (data) => API.UploadPOST(data);
export const UploadOSS = (data) => API.UploadOSS(data);
// 下载
export const download = (data) => API.Download(data);
export const downloadOSS = (data) => API.DownloadOSS(data);
// 下载
export const downLoadFile = (url, data) => API.DownLoadFile(url, data);
// 处理记录查询
export function uploadFile(data) {
  return request({
    url: 'expensesType/importExpensesType?token=' + sessionStorage.getItem('token'),
    data: data,
    method: 'post',
    headers: {
      "Content-Type": "multipart/form-data"
    }
  })
}
// 批量导入对象
export function objectUploadFile(data) {
  return request({
    url: 'expensesTarget/importExpensesType',
    method: 'post',
    data: data,
    headers: {
      "Content-Type": "multipart/form-data"
    }
  })
}
// 批量导入账号
export function accountUploadFile(data) {
  return request({
    url: 'bank/importExpensesType',
    method: 'post',
    data: data,
    headers: {
      "Content-Type": "multipart/form-data"
    }
  })
}
// 批量导入台账
export function importCashierAccount(data) {
  return request({
    url: 'cashierAccount/importCashierAccount',
    method: 'post',
    data: data,
    headers: {
      "Content-Type": "multipart/form-data"
    }
  })
}
// 批量导入流水
export function importFlowRecord(data) {
  return request({
    url: 'cashierAccount/importFlowRecord',
    method: 'post',
    data: data,
    headers: {
      "Content-Type": "multipart/form-data"
    }
  })
}
// 批量导入登记
export function registerUploadFile(data) {
  return request({
    url: 'finance/importExpensesType',
    method: 'post',
    data: data,
    headers: {
      "Content-Type": "multipart/form-data"
    }
  })
}


// 资产关联名称
export const findByAssetName = (data) => API.GET('assetRecord/findByAssetName', data);

// 费用发票分页
export const pageExpenseInvoice = (data) => API.GET('expenseInvoice/pageExpenseInvoice', data);
// 查询附件
export const findByBusinessId = (data) => API.GET('attach/findByBusinessIdAndType', data);
// 查询自定义内容列表
export const pageCustom = (data) => API.GET('custom/pageCustom', data);
// 获取超管信息
export const getAdmin = (data) => API.GET('member/findByCompanyIsSuperAdmin', data);
// 获取所有人员
export const pageMember = (data) => API.GET('member/pageMember', data);
// 修改超管
export const updateIsSuperAdmin = (data) => API.GET('member/updateIsSuperAdmin', data);