import * as API from './index'

// 通过token登录
export const queryLogin = (data) => API.GET2('sso/queryLogin', data);
// 通过token登录
export const queryUser = (data) => API.GET2('company/licenseManagement/queryLicenseUserOrCarNameComboBox', data);
// 超管联系信息
export const getComDetailInfo = (data) => API.GET2('company/company/getComDetailInfo', data);
// 发送短信
export const sms = (data) => API.GET2('sms/common/sms', data);
// 短信验证
export const check = (data) => API.GET2('sms/common/sms/check', data);
// 查询可导入对象
export const queryCompanyAllObjects = (data) => API.GET2('company/company/queryCompanyAllObjects', data);