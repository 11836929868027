import axios from 'axios'
import {MessageBox, Notification} from 'element-ui'

const request = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  // timeout: 5000, // request timeout
});

request.interceptors.request.use(function (config) {
//   config.headers['x-access-token'] = sessionStorage.getItem('x-access-token')? sessionStorage.getItem('x-access-token').split('_')[0] + '_ms':'';
  config.headers['x-access-token'] = sessionStorage.getItem('x-access-token')? sessionStorage.getItem('x-access-token'):'';
  return config;
}, function (error) {
  return Promise.reject(error);
});

request.interceptors.response.use(function (response) {
  const {status, data} = response
  if (status >= 200 && status < 300) {
      if (data.success) {
          return response.data
      } else if (data.msg === 'no_user') {
          MessageBox.alert(
              '登录状态已过期，或已在其他地方登陆！',
              '系统提示',
              {
                  type: 'warning'
              }
          ).then(() => {
              store.dispatch('user/logout').then(() => {
                  window.location = process.env['VUE_APP_VV']
              })
          })
          return Promise.reject(response)
      } else {
          Notification.error(data.msg)
          return Promise.reject(response)
      }
  }
  // 对响应数据做点什么
  return response;
}, function (error) {
  switch (error.response.status) {
      case 500:
          Notification.error("系统繁忙")
          break;
      case 403:
          MessageBox.alert(
              '登录状态已过期，或已在其他地方登陆！',
              '系统提示',
              {
                  type: 'warning'
              }
          ).then(() => {
              store.dispatch('user/logout').then(() => {
                  // router.push({path: '/login'})
                  window.location = process.env['VUE_APP_VV']
              })
          })
          // Notification.error("非法访问")
          break;
      case 504:
          Notification.error("连接超时")
          break;
      default:
          Notification.error(error.response.status + "")
          break;
  }
  // 对响应错误做点什么
  return Promise.reject(error);
});

export default request