import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

// import {
//     staffCountNum
// } from "apiCompany/staff";

// import {setSessionUNM,getSessionUNM} from "../components/messageCenter/NumUnMessages"

// import assignmentOrder from './assignmentOrder'
// import orderDetail from './orderDetail'
const store = new Vuex.Store({
	modules: {
		// assignmentOrder,
		// orderDetail
		// test
	},
	state: {
		companyId: '', // 所属企业id
		shortname: '', // 所属企业简称
		isSuperAdmin: '', // 当前登录用户是否超管
		carNum: 0,
		waitCarNum: 0,
		urgeIds: [], // 催单的订单ID
		urgeIdsExist: [], // 已存在催单的订单ID
		wsPartnerState: 0, // websocket收到消息时加1,
		buildNews: [], // 关系建立列表有新的关系需要处理
		staffCount: null,
		currentRouter: '',
		unreadMesNum: 0, //中台未读消息数量
		//unmessSession:sessionStorage.getItem("unreadMesNum")
		unOperationsNum: 0,
		unSchedulingNum: 0,
		unOrderRelatedNum: 0,
		unCarNum: 0,
		unPersonNum: 0,
		unSalesNum: 0,
		unFeedbackNum: 0,
		unEnterpriseVNum: 0,
		isAllRead: 1,
		tagRead: 0,
		stepRouterList: [],
		stepRouterIndex: 0,
		selectRouterObj: null,
		createStatus: 1, // 订单创建/复制成功状态,
		userInfo: sessionStorage.getItem("access-user") ? JSON.parse(sessionStorage.getItem("access-user")) : {},
		role: [],
		isAdmin: 0,
		authVisible: false,
		keepArray: [],
	},
	getters: {
		getKeepArray: state => {
			let res = state.stepRouterList.map((item)=> {
				return item.meta.name
			})
			return res
		},
		getCarNum: state => {
			return state.carNum
		},
		getWaitCarNum: state => {
			return state.waitCarNum
		},
		getStaffCount: state => {
			return state.staffCount
		},
		getUnreadMesNub: state => {
			return state.unreadMesNum
		},
		getUnOperations: state => {
			return state.unOperationsNum
		},
		getSchedulingNum: state => {
			return state.unSchedulingNum
		},
		getOrderCorrNum: state => {
			return state.unOrderRelatedNum
		},
		getCarRelatedNum: state => {
			return state.unCarNum
		},
		getPersonNum: state => {
			return state.unPersonNum
		},
		getSalesNum: state => {
			return state.unSalesNum
		},
		getFeedbackNum: state => {
			return state.unFeedbackNum
		},
		getunEnterpriseVNum: state => {
			return state.unEnterpriseVNum
		},
		getisAllRead: state => {
			return state.isAllRead
		},
		gettagRead: state => {
			return state.tagRead
		},
		getSelectRouterObj: state => {
			return JSON.parse(state.selectRouterObj)
		},
		getCreateStatus: state => {
			return state.createStatus
		},

	},
	mutations: {
		changeCarNum(state, num) {
			state.carNum = num
		},
		changeWaitCarNum(state, num) {
			state.waitCarNum = num
		},
		changeStaffCount(state, arr) {
			state.staffCount = null
			state.staffCount = arr
		},
		uMN(state, num) {
			state.unreadMesNum = num
		},
		unOperationsMZ(state, num) {
			state.unOperationsNum = num
		},
		unSchedulingMZ(state, num) {
			state.unSchedulingNum = num
		},
		unOrderRelatedMZ(state, num) {
			state.unOrderRelatedNum = num
			// console.log(num);
		},
		unCarMZ(state, num) {
			state.unCarNum = num
			// console.log(state.unCarNum)
		},
		unPersonMZ(state, num) {
			state.unPersonNum = num
		},
		unSalesMZ(state, num) {
			state.unSalesNum = num
		},
		unFeedback(state, num) {
			state.unFeedbackNum = num
		},
		unEnterpriseV(state, num) {
			state.unEnterpriseVNum = num
		},
		changeisAllRead(state, num) {
			state.isAllRead = num
		},
		changetagRead(state, num) {
			state.tagRead = num
		},
		pushStepRouterList(state, obj) {
			state.stepRouterList.push(obj);
		},
		spliceStepRouterList(state, index) {
			state.stepRouterList.splice(index, 1);
		},
		setStepRouterIndex(state, index) {
			state.stepRouterIndex = index;
		},
		setSelectRouterObj(state, obj) {
			state.selectRouterObj = JSON.stringify(obj);
		},
		setCreateStatus(state, num) {
			state.createStatus = num;
		},
		setUserInfo(state, info) {
			state.userInfo = info;
		},
		setRole(state, payload) {
			let info = payload || {}
			state.isAdmin = info.isSuperAdmin || 0
			state.role = info.role || []
		},
		setAuthVisible(state, payload) {
			state.authVisible = payload
		}
	},
	actions: {
		activeStaffCount({
			commit
		}) {
			let user = JSON.parse(sessionStorage.getItem('access-user'))
			staffCountNum({
				companyId: user.companyId
			}).then(res => {
				commit('changeStaffCount', res.data.split(','))
			})
		}
	}
})
export default store
