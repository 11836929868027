//正则表达式汇总

let regex = {
    //工号
    jobNo: /^[A-Z0-9]{0,8}$/,
    //手机号码
    phone: /^[0-9]{1,20}$/,
    //注册手机号码
    // registerPhone: /^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/,
    registerPhone:/^1[3-9]\d{9}$/,
    //邮箱
    email: /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,8})$/,
    //座机
    landline: /^[0-9\-]{1,20}$/,
    //车牌号(包括新能源车)
    license: /^([京津晋冀蒙辽吉黑沪苏浙皖闽赣鲁豫鄂湘粤桂琼渝川贵云藏陕甘青宁新][ABCDEFGHJKLMNPQRSTUVWXY][0-9A-Z][0-9ABCDEFGHJKLMNPQRSTUVWXYZ]\d{3}[0-9A-Z]|[京津晋冀蒙辽吉黑沪苏浙皖闽赣鲁豫鄂湘粤桂琼渝川贵云藏陕甘青宁新][ABCDEFGHJKLMNPQRSTUVWXY][\dABCDEFGHJKLNMxPQRSTUVWXYZ]{5})$/,
    //车牌号(包括特种车牌(挂,警,学,领,港,澳))
    license1: /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领]{1}[A-Z]{1}(([0-9]{5}[DF])|([DF][A-HJ-NP-Z0-9][0-9]{4})|([A-HJ-NP-Z0-9]{4}[A-HJ-NP-Z0-9挂警学领港澳]{1}))$/,
    //车架号 30位数字及字母组合
    // vin: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z\-]{1,30}$/,
    vin: /^[0-9A-Za-z\-]{1,30}$/,
    //发动机号
    engine: /^[0-9A-Za-z]{1,16}$/,
    //车辆编号 6位字母或数字
    carNum: /^[a-zA-Z0-9]{0,6}$/,
    //英文名
    engName: /^(?!\s+$)[a-zA-Z\s·]+$/,
    //两位小数
    twoDecimals: /^\d+(\.\d{0,2})?$/
}
export default regex

