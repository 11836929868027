<template>
  <el-dialog
    :visible="visible"
    :close-on-click-modal="false"
    top="15vh"
    append-to-body
    v-dialogDragOnly
    :show-close="false"
  >
    <div class="dialog-content" v-if="admin">
      <div class="auth-header">
        <div>超管授权</div>
        <div>需经中台超管授权</div>
      </div>

      <div class="auth-content">
        <el-form ref="form" :model="form" :rules="rules" label-width="120px">
          <el-form-item label="当前超管：">
            <div>{{ admin.nickname }} {{ admin.mobile }}</div>
          </el-form-item>
          <el-form-item label="短信验证码：" required prop="sms">
            <div
              style="
                display: flex;
                align-item: center;
                border-bottom: 1px solid #ccc;
              "
            >
              <div style="width: 100px">
                <el-input v-model="form.sms"></el-input>
              </div>

              <div class="sendCode" @click="sendCodeVV">
                {{ sec }}<span v-if="sec >= 0">s后重新获取</span>
              </div>
            </div>
          </el-form-item>
        </el-form>
      </div>

      <div class="btn-group">
        <div class="custom-btn custom-btn-cancel" @click="handleClose">
          取消
        </div>
        <div class="custom-btn" @click="handleSend">确定</div>
        <!-- <SubmitBtn :loading="loading" txt="确定" @click.native="handleSend" /> -->
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { sms, check } from "@/api/user";
import { getAdmin } from "@/api/finance";
import { mapMutations } from "vuex";
export default {
  props: ["visible"],
  data() {
    return {
      // admin: "",
      mobile: "",
      form: {
        sms: "",
      },
      rules: {
        sms: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
      sec: "获取验证码",
      clear: null,
      isClick: false,
      isLoading: false,
      loading: false,
      admin: null,
    };
  },
  created() {
    this.getAdmin();
  },
  methods: {
    ...mapMutations(["setAuthVisible"]),
    getAdmin() {
      getAdmin().then((res) => {
        this.admin = res.item;
        if (res.item) {
          this.admin = res.item;
          this.mobile = this.admin.mobile;
        }
      });
    },

    countDown() {
      var _this = this;
      if (_this.sec > 0) {
        --_this.sec;
      } else {
        clearInterval(_this.clear);
        _this.sec = "重新获取";
        this.isClick = false;
      }
    },
    sendCodeVV() {
      // this.$refs.form.validate("mobile").then((res) => {
      if (this.sec !== "获取验证码" && this.sec !== "重新获取") return;

      if (this.isClick) return;

      this.isClick = true;

      sms({
        phone: this.mobile,
        type: 2,
      })
        .then((res) => {
          this.sec = 60;
          this.$message.success("发送成功");
          this.clear = setInterval(this.countDown, 1000);
        })
        .catch((err) => {
          this.$message.error(err.data.msg);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleClose() {
      this.setAuthVisible(false);
      // this.$emit("update:visible", false);
    },
    handleSend() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;

          check({
            phone: this.mobile,
            vcode: this.form.sms,
          })
            .then((res) => {
              if (res.success) {
                this.$message.success("授权成功");
                let user = this.getsStorage("access-user");
                if (user) {
                  let now = this.$dayjs();
                  let end = now.add(30, "minute");

                  user.authTime = end.valueOf();
                  sessionStorage.setItem("access-user", JSON.stringify(user));
                }
                // location.reload()
                this.$emit("success");
                this.handleClose();

                // let temp = sessionStorage.getItem("access-user");
                // if (temp) {
                //   let info = JSON.parse(sessionStorage.getItem("access-user"));
                //   info.timestamp = new Date().getTime();
                //   sessionStorage.setItem("access-user", JSON.stringify(info));
                //   // this.$EventBus.$emit('auth', 1)
                // }
                // this.handleClose();
              } else {
                this.$message.error(res.msg);
              }
            })
            .catch((err) => {
              this.$message.error(err.data.msg);
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog {
  width: 350px !important;
  background: #ffffff;
  border-radius: 6px;
  box-sizing: border-box;
  .el-dialog__body {
    padding: 0px;
  }
  .el-dialog__header {
    min-height: 0;
    padding: 0px;
    // background: rgba(52, 118, 255, 0.1);
    // font-weight: bold;
    // .el-dialog__title {
    //   font-size: 16px;
    //   color: #3e4576;
    // }
  }
  .dialog-content {
    padding-bottom: 20px;
    // line-height: 1.4;

    .auth-header {
      padding: 20px 20px 10px;
      border-bottom: 1px solid #ccc;
      text-align: center;
      div:nth-of-type(1) {
        font-size: 18px;
        color: #3476ff;
      }
      div:nth-of-type(2) {
        font-size: 12px;
        padding: 5px;
        color: rgb(153, 153, 153);
      }
    }

    .auth-content {
      padding: 30px 20px 30px 10px;
      .sendCode {
        flex: 1;
        text-align: right;
        font-size: 14px;
        color: #3476ff;
      }
      .el-input__inner {
        border: none;
      }
    }

    .btn-group {
      display: flex;
      justify-content: flex-end;
      padding-right: 20px;
      .custom-btn {
        height: 36px;
        line-height: 36px;
        padding: 0 20px;
        font-size: 14px;
        color: #fff;
        background: #3476ff;
        margin-right: 20px;
        border-radius: 6px;
        cursor: pointer;
      }
      .custom-btn-cancel {
        color: #3476ff;
        background: #eaf1ff;
      }
    }
  }
}
</style>
