<template>
	<el-dialog
		:visible.sync="visible"
		width="600px"
		:modal="modal"
		v-dialogDragOnly
		:before-close="close"
	>
		<header class="image-header" slot="title">
			<span>{{ title }}</span>
		</header>
		<div style="max-height: 500px; overflow: hidden;text-align: center;overflow:auto"  v-if="data.length <= 1">
			<img class="image-item" :src="data[0] || ''" alt="">
		</div>
		<el-carousel trigger="click" height="500px"
   :autoplay="false" v-else>
			<el-carousel-item v-for="(item, index) in data" :key="index" style="display:flex;justify-content: center;align-items: center">
				<img class="image-item" :src="item">
			</el-carousel-item>
		</el-carousel>
	</el-dialog>
</template>
<script>
export default {
}
</script>
<style lang="scss" scoped>
.image-item {
    width: auto;
    height: auto;
    max-height: 100%;
    max-width: 100%;
}
.image-header {
	color: #333;
	font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>


