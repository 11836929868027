import axios from 'axios'
import { bus } from '../bus.js'
import { MessageBox, Notification } from 'element-ui'

axios.interceptors.request.use(function (config) {
  config.headers['x-access-token'] = sessionStorage.getItem('x-access-token') ? sessionStorage.getItem('x-access-token') : '';

  return config;
}, function (error) {
  console.info(error);
  return Promise.reject(error);
});

// 添加响应拦截器
axios.interceptors.response.use(function (response) {
  let { status, data } = response

  if (data instanceof Blob && data.type === 'application/json') {
    const reader = new FileReader()
    reader.readAsText(data, 'utf-8')
    reader.onload = () => {
      data = JSON.parse(reader.result)
      if (data.msg) {
        Notification.error(data.msg)
      }
    }

    return Promise.reject(response)
  } else if (data instanceof Blob) {
    return response
  } else {
    if (status >= 200 && status < 300) {
      if (data.success) {
        return response
      } else if (data.msg === 'no_user') {
        MessageBox.alert(
          '登录状态已过期，或已在其他地方登陆！',
          '系统提示',
          {
            type: 'warning'
          }
        ).then(() => {
          window.location = process.env['VUE_APP_VV']
        })
        return Promise.reject(response)
      } else {
        if (data.msg) {
          Notification.error(data.msg)
        }
        return Promise.reject(response)
      }
    }
    return response;
  }
}, function (error) {
  if (!error.response) {
    return Promise.reject(error);
  }

  switch (error.response.status) {
    case 500:
      Notification.error("系统繁忙")
      break;
    case 403:
      MessageBox.alert(
        '登录状态已过期，或已在其他地方登陆！',
        '系统提示',
        {
          type: 'warning'
        }
      ).then(() => {
        store.dispatch('user/logout').then(() => {
          window.location = process.env['VUE_APP_VV']
        })
      })
      break;
    case 504:
      Notification.error("连接超时")
      break;
    default:
      Notification.error(error.response.status + "")
      break;
  }
  return Promise.reject(error);
});


let base = process.env['VUE_APP_BASE_API']
export const GET = (url, params, signal, baseUrl = base) => {
  const getTimestamp = new Date().getTime();

  return axios.get(`${baseUrl}${url}?timer=${getTimestamp}`, {
    params: params,
    signal: signal
  }).then(res => res.data)
}
export const POST = (url, data, signal, baseUrl = base) => {
  return axios.post(`${baseUrl}${url}`, data, {
    signal: signal
  }).then(res => res.data)
}

export const Download = (url) => {
  const getTimestamp = new Date().getTime();
  let proxyName = process.env.NODE_ENV === 'production' ? '' : '/server'
  return axios.get(`${proxyName}${url}?timer=${getTimestamp}`, { responseType: "blob" }).then(res => res.data)
}
export const DownloadOSS = (url) => {
  const getTimestamp = new Date().getTime();
  let proxyName = process.env.NODE_ENV === 'production' ? '' : '/oss'
  return axios.get(`${proxyName}${url}?timer=${getTimestamp}`, { responseType: "blob" }).then(res => res.data)
}

export const DownLoadFile = (url, params) => {
  const getTimestamp = new Date().getTime();
  return axios.get(`${base}${url}?timer=${getTimestamp}`, { params: params, responseType: "blob" }).then(res => res)
}

// 上传文件
export const UploadPOST = (params) => {
  return axios.post(process.env['VUE_APP_BASE_UPLOAD'], params, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  }).then(res => res.data)
}
// 上传文件到OSS
export const UploadOSS = (params) => {
  return axios.post(process.env['VUE_APP_OSS_UPLOAD'], params, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  }).then(res => res.data)
}


let commonAxios = axios.create({})
commonAxios.interceptors.request.use(function (config) {
  config.headers['token'] = sessionStorage.getItem('token')
  return config;
}, function (error) {
  console.info("error: ");
  console.info(error);
  return Promise.reject(error);
})

// 添加一个响应拦截器
commonAxios.interceptors.response.use(function (response) {
  if (response.data && response.data.code) {
    if (parseInt(response.data.code) === 108 || parseInt(response.data.code) === 109 || response.data.msg === 'TOKEN失效，请重新登录' || response.data.msg === 'TOKEN不存在' || response.data.msg === 'no_user') {
      //未登录
      response.data.msg = "登录信息已失效，请重新登录";
      bus.$message.error(response.data.msg);
      setTimeout(() => {
        window.location = process.env['VUE_APP_VV']
      }, 1500);
    }
    if (parseInt(response.data.code) === -1) {
      bus.$message.error("请求失败");
    }
  } else if (response.data.success === false) {
    Notification.error(response.data.msg)
    return Promise.reject(response)
  }
  return response;
}, function (error) {
  console.dir(error);
  bus.$message.error("服务器连接失败");
  return Promise.reject(error);
})

let base2 = process.env['VUE_APP_VV_API']
export const GET2 = (url, params, baseUrl = base2) => {
  const getTimestamp = new Date().getTime();

  return commonAxios.get(`${baseUrl}${url}?timer=${getTimestamp}`, {
    params: params
  }).then(res => res.data)
}


