import Vue from 'vue'
import pic from './showPic'

const picExtend = Vue.extend(pic)

const picture = (name, url, type) => {
    const picInstance = new picExtend({
        data: {
            name: name,
            urlString: url,
            imgFlag: false,
            imgName:name,
            imgType:type, // type=1 是视频文件，仅限单个预览
            urls:null,
            n:0,
            flag:true
        },
        created() {
            if (url) this.imgFlag = true
            this.urls = this.urlString.split(',')
        },
        methods: {
            move(type) {
                if (type > 0) {
                    this.n++
                    if (this.n >= this.urls.length) this.n = this.urls.length - 1
                } else {
                    this.n--
                    if (this.n <= 0) this.n = 0
                }
                let uls = document.getElementsByClassName('img-uls')[0]
                uls.style.transform = `translateX(-${600 * this.n}px)`
            },
            close() {
                this.imgFlag = false
            }
        }
    })
    picInstance.vm = picInstance.$mount()
    picInstance.dom = picInstance.vm.$el
    document.body.appendChild(picInstance.dom) // 将dom插入body
}
export default {
    install: Vue => {
        Vue.prototype.$picBox = picture
    }
}
